import { __awaiter, __generator } from "tslib";
import superagent from 'superagent';
import { readCookie, removeCookie, writeCookie } from '../utils/cookies';
export var ACCESS_TOKEN = 'AccessTokenCMS';
var BASE_URL = 'https://slotletselschade.appec.nl/api/v1/admin/';
export var EndPoints;
(function (EndPoints) {
    EndPoints["login"] = "login/";
    EndPoints["users"] = "users/";
    EndPoints["usersCreate"] = "users/create/";
    EndPoints["usersDelete"] = "users/delete/";
    EndPoints["feed"] = "feed/";
    EndPoints["feedParse"] = "feed/parse/";
    EndPoints["faq"] = "faq/";
    EndPoints["faqCreate"] = "faq/create/";
    EndPoints["faqUpdate"] = "faq/update/";
    EndPoints["faqPosition"] = "faq/position/";
    EndPoints["faqDelete"] = "faq/delete/";
    EndPoints["config"] = "config/";
    EndPoints["configUpdate"] = "config/update/";
    EndPoints["push"] = "push/";
})(EndPoints || (EndPoints = {}));
export function checkIfLogged() {
    return readCookie(ACCESS_TOKEN) !== null;
}
export function setAuthToken(token) {
    writeCookie(ACCESS_TOKEN, token, 365);
}
export function getAuthToken() {
    return readCookie(ACCESS_TOKEN) || '';
}
export function post(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .post(BASE_URL + endPoint)
                    .set('Content-Type', 'application/json')
                    .set({ 'Authorization': 'Bearer ' + getAuthToken() })
                    .send(data)
                    .accept('application/json')
                    .then(function (result) {
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            window.location = window.location.pathname;
                        }
                        else {
                            throw result.body.data.error.message;
                        }
                    }
                    return result.body.data;
                })];
        });
    });
}
export function get(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .get(BASE_URL + endPoint)
                    .set('Content-Type', 'application/json')
                    .set({ 'Authorization': 'Bearer ' + getAuthToken(), 'Language': 'nl' })
                    .query(data)
                    .accept('application/json')
                    .then(function (result) {
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            window.location = window.location.pathname;
                        }
                        else {
                            throw result.body.data.error.message;
                        }
                    }
                    return result.body.data;
                })];
        });
    });
}
export function del(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .delete(BASE_URL + endPoint)
                    .set('Content-Type', 'application/json')
                    .set({ 'Authorization': 'Bearer ' + getAuthToken() })
                    .send(data)
                    .accept('application/json')
                    .then(function (result) {
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            window.location = window.location.pathname;
                        }
                        else {
                            throw result.body.data.error.message;
                        }
                    }
                    return result.body.data;
                })];
        });
    });
}
