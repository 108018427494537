import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import SunEditor from 'suneditor-react';
import { createFaq, updateFaq } from '../../api/faq';
import { FaqCategory } from '../../models/Faq';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { Modal } from '../../components/Modal';
import S from './faq.styl';
require('suneditor/dist/css/suneditor.min.css');
var AddFaqModal = /** @class */ (function (_super) {
    __extends(AddFaqModal, _super);
    function AddFaqModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            titleInputError: false,
            contentInputError: false,
            selectedCategory: FaqCategory.Faq,
            content: ''
        };
        _this.ref = null;
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'title') {
                _this.setState({ titleInputError: false });
            }
            if (name === 'content') {
                _this.setState({ contentInputError: false });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        _this.onClose = function () {
            if (_this.props.onClose) {
                _this.props.onClose();
            }
        };
        _this.onCategoryChange = function (category) {
            _this.setState({
                selectedCategory: category,
            });
        };
        _this.handleInputChange = function (content) {
            _this.setState({ content: content });
        };
        return _this;
    }
    AddFaqModal.prototype.componentDidMount = function () {
        if (this.props.faq) {
            this.setState({
                selectedCategory: this.props.faq.category || FaqCategory.Faq,
                content: this.props.faq.content || ''
            });
        }
    };
    AddFaqModal.prototype.onSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var valid = true;
        var title = elements.title.value;
        var content = this.state.content;
        if (title.length === 0) {
            this.setState({ titleInputError: true });
            valid = false;
        }
        if (content.length === 0) {
            this.setState({ contentInputError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        var request = {
            title: title,
            content: content,
            id: this.props.faq && this.props.faq.id,
            category: this.state.selectedCategory,
        };
        this.setState({ loading: true });
        if (!!this.props.faq) {
            updateFaq(request).then(function () {
                _this.setState({ loading: false });
                _this.isUpdated();
            }).catch(function (error) {
                _this.setState({ loading: false });
                alert(error);
            });
        }
        else {
            createFaq(request).then(function () {
                _this.setState({ loading: false });
                _this.isUpdated();
            }).catch(function (error) {
                _this.setState({ loading: false });
                alert(error);
            });
        }
    };
    AddFaqModal.prototype.render = function () {
        var _this = this;
        var _a = this, props = _a.props, state = _a.state;
        var i18n = props.store.i18n;
        var loading = state.loading;
        return (React.createElement(Modal, { className: props.className, onRequestClose: props.onClose },
            React.createElement("div", { className: S.faqAddView },
                React.createElement("p", { className: S.faqHeaderText }, props.faq ?
                    i18n.faqUpdate
                    :
                        i18n.faqCreate),
                React.createElement("form", { className: "" + S.addDialogContent, method: "get", action: "/", onSubmit: this.onSubmit, noValidate: true },
                    React.createElement("div", null,
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.faqFormTitle),
                            React.createElement("input", { className: S.addInput + " textInput", name: "title", onInput: this.onChangeAction, placeholder: i18n.faqFormTitle, defaultValue: props.faq && props.faq.title })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.titleInputError ? 'inputErrorActive' : '') }, i18n.validationTitleError),
                        React.createElement("div", { className: S.addTextareaWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.faqFormContent),
                            React.createElement(SunEditor, { onChange: this.handleInputChange, defaultValue: props.faq && props.faq.content, height: 250 })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.contentInputError ? 'inputErrorActive' : '') }, i18n.validationContentError),
                        React.createElement(Dropdown, { maxHeight: 190, isFixed: true, title: i18n.faqCategory, className: "modalDropDown " + S.addInputWrapper + " " + S.dropdown, boxClass: "modalDropDownBox", label: "" + (state.selectedCategory === FaqCategory.Faq ? i18n.faqCategoryFaq : i18n.faqCategoryClaim) },
                            React.createElement(Dropdown.List, null,
                                React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function (_) { return _this.onCategoryChange(FaqCategory.Faq); } }, i18n.faqCategoryFaq),
                                React.createElement(Dropdown.Item, { data: "categoryItem", autoClose: true, onClick: function (_) { return _this.onCategoryChange(FaqCategory.Claim); } }, i18n.faqCategoryClaim)))),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.faqModalButton, type: "submit", spinner: loading }, props.faq ?
                        i18n.actionUpdate
                        :
                            i18n.actionCreate),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.faqModalButton + " " + S.adminsModalCancel, type: "button", spinner: loading, onClick: this.onClose }, i18n.alertModalCancel)))));
    };
    __decorate([
        action.bound
    ], AddFaqModal.prototype, "onSubmit", null);
    AddFaqModal = __decorate([
        inject('store'),
        observer
    ], AddFaqModal);
    return AddFaqModal;
}(React.Component));
export { AddFaqModal };
