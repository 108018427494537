import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { deleteUser } from '../api/users';
import { UserRole } from '../models/User';
import { customConfirm } from '../utils/utils';
import { Button } from './Button';
import S from './UserItem.styl';
var UserItem = /** @class */ (function (_super) {
    __extends(UserItem, _super);
    function UserItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
        };
        _this.deleteItem = function () {
            var i18n = _this.props.store.i18n;
            var user = _this.props.user;
            if (user) {
                customConfirm(i18n.adminsDeleteUserSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        var request = {
                            id: user.id,
                        };
                        deleteUser(request).then(function () {
                            _this.setState({ loading: false });
                            _this.isUpdated();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        return _this;
    }
    UserItem.prototype.componentDidMount = function () {
        // Did Mount
    };
    UserItem.prototype.render = function () {
        var props = this.props;
        var user = props.user;
        var i18n = props.store.i18n;
        var loading = this.state.loading;
        return (React.createElement("div", { className: S.userItem }, user ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: S.email }, user.email),
                React.createElement("div", { className: S.firstName }, user.firstName),
                React.createElement("div", { className: S.lastName }, user.lastName),
                user.role !== UserRole.SuperAdmin &&
                    React.createElement("div", { className: S.action },
                        React.createElement(Button, { className: S.deleteButton, type: "button", onClick: this.deleteItem }, i18n.actionDelete)))
            :
                React.createElement(React.Fragment, null)));
    };
    UserItem = __decorate([
        inject('store'),
        observer
    ], UserItem);
    return UserItem;
}(React.Component));
export { UserItem };
