import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
// import {pushHistory} from '../router/actions';
// import {Route} from '../router/routes';
import S from './customers.styl';
var CustomersView = /** @class */ (function (_super) {
    __extends(CustomersView, _super);
    function CustomersView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomersView.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        return (React.createElement("div", { className: S.customersView },
            React.createElement("h1", { className: S.customersTitle }, i18n.customersTitle),
            React.createElement("hr", { className: S.customersSeparator }),
            React.createElement("div", { className: S.customersMainBox })));
    };
    CustomersView = __decorate([
        inject('store'),
        observer
    ], CustomersView);
    return CustomersView;
}(React.Component));
export { CustomersView };
