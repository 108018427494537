import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { pushHistory } from '../router/actions';
import { setAuthToken } from '../api';
import { login } from '../api/login';
import { Await } from '../components/Await';
import { Button } from '../components/Button';
import { Route } from '../components/Link';
import logo from '../images/logo.png';
import SS from './auth.styl';
import { AuthModal } from './AuthModal';
import S from './LoginView.styl';
var LoginView = /** @class */ (function (_super) {
    __extends(LoginView, _super);
    function LoginView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            emailError: false,
            passwordError: false,
            isLoading: false,
            source: null,
            defaultUserName: '',
        };
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'emailOrPhone') {
                _this.setState({ emailError: false });
            }
            if (name === 'password') {
                _this.setState({ passwordError: false });
            }
        };
        _this.backToHome = function () {
            pushHistory(_this.props.store, Route.Home);
        };
        return _this;
    }
    LoginView.prototype.onSubmit = function (event) {
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var store = this.props.store;
        var valid = true;
        var emailOrPhone = elements.emailOrPhone.value;
        var password = elements.password.value;
        if (emailOrPhone.length === 0) {
            this.setState({ emailError: true });
            valid = false;
        }
        if (password.length === 0) {
            this.setState({ passwordError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        this.setState({
            source: login(emailOrPhone, password).then(function (result) {
                setAuthToken(result.token || '');
                window.location = window.location.pathname;
            }).catch(function (error) {
                alert(error.toString());
                throw error;
            }),
        });
    };
    LoginView.prototype.render = function () {
        var _this = this;
        var i18n = this.props.store.i18n;
        var state = this.state;
        return (React.createElement(Await, { source: state.source }, function (_a) {
            var pending = _a.pending;
            return (React.createElement(AuthModal, { position: "static" },
                React.createElement("div", { className: SS.authHeaderLogoContent + ' ' + SS.clickable, onClick: _this.backToHome },
                    React.createElement("img", { src: logo }),
                    React.createElement("h1", { className: SS.authHeaderNameWhite }, "CMS")),
                React.createElement("div", { className: SS.authWhiteBox },
                    React.createElement("p", { className: SS.authBoxHeaderText }, i18n.authLogin),
                    React.createElement("form", { className: "" + SS.authDialogContent, method: "get", action: "/", onSubmit: _this.onSubmit, noValidate: true },
                        React.createElement("div", { className: S.loginViewFields },
                            React.createElement("div", { className: SS.authInputWrapper },
                                React.createElement("label", { className: SS.authInputTitle }, i18n.loginViewEmailPlaceholder),
                                React.createElement("input", { className: SS.authInput + " textInput", name: "emailOrPhone", type: "email", autoComplete: "username", onInput: _this.onChangeAction, placeholder: i18n.loginViewEmailPlaceholder, defaultValue: _this.state.defaultUserName })),
                            React.createElement("p", { className: "inputError " + SS.authInputError + " " + (_this.state.emailError ? 'inputErrorActive' : '') }, i18n.validationEmailError),
                            React.createElement("div", { className: SS.authInputWrapper },
                                React.createElement("label", { className: SS.authInputTitle }, i18n.loginViewPasswordPlaceholder),
                                React.createElement("input", { className: SS.authInput + " textInput", name: "password", type: "password", autoComplete: "current-password", onInput: _this.onChangeAction, placeholder: i18n.loginViewPasswordPlaceholder })),
                            React.createElement("p", { className: "inputError " + SS.authInputError + " " + (_this.state.passwordError ? 'inputErrorActive' : '') }, i18n.validationPasswordError)),
                        React.createElement(Button, { className: "primaryButton fullwidthButton", type: "submit", spinner: pending }, i18n.loginViewSignIn)))));
        }));
    };
    __decorate([
        action.bound
    ], LoginView.prototype, "onSubmit", null);
    LoginView = __decorate([
        inject('store'),
        observer
    ], LoginView);
    return LoginView;
}(React.Component));
export { LoginView };
