var _a;
import { action, runInAction } from 'mobx';
import React from 'react';
import { SideBarItem } from '../components/SideBar';
import { parseRouteLocation, replaceHistory } from './actions';
import { Route } from './routes';
import { setActiveScreen } from './transitions';
import { AuthLayout } from '../auth/AuthLayout';
import { MainLayout } from '../layouts/MainLayout';
import { Authenticator } from '../auth/Authenticator';
import { LoginView } from '../auth/LoginView';
import { AdminsView } from '../modules/admins/AdminsView';
import { ClaimsView } from '../modules/claims/ClaimsView';
import { CustomersView } from '../modules/customers/CustomersView';
import { DashboardView } from '../modules/dashboard/Dashboard';
import { FaqView } from '../modules/faq/FaqView';
import { FeedView } from '../modules/feed/FeedView';
import { PushNotificationsView } from '../modules/pushNotifications/PushNotificationsView';
import { SettingsPage, SettingsView } from '../modules/settings/SettingsView';
var ROUTES = (_a = {},
    _a[Route.Home] = function (store) { return setActiveScreen(store, React.createElement(AuthLayout, { isWhiteBackground: true, children: React.createElement(LoginView, null) })); },
    _a[Route.Dashboard] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(DashboardView, null), activeItem: SideBarItem.Dashboard }) })); },
    _a[Route.Admins] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(AdminsView, null), activeItem: SideBarItem.Admins }) })); },
    _a[Route.Customers] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(CustomersView, null), activeItem: SideBarItem.Customers }) })); },
    _a[Route.Claims] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(ClaimsView, null), activeItem: SideBarItem.Claims }) })); },
    _a[Route.PushNotifications] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(PushNotificationsView, null), activeItem: SideBarItem.PushNotifications }) })); },
    _a[Route.Faq] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(FaqView, null), activeItem: SideBarItem.Faq }) })); },
    _a[Route.Feed] = function (store, loc) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(FeedView, null), activeItem: SideBarItem.Feed }) })); },
    _a[Route.Settings] = function (store) { return setActiveScreen(store, React.createElement(Authenticator, { children: React.createElement(MainLayout, { children: React.createElement(SettingsView, { currentPage: SettingsPage.CompanyDetails }), activeItem: SideBarItem.Settings }) })); },
    _a);
var notFound = function (store) {
    // TODO: display "not found" page
    replaceHistory(store, Route.Home);
};
export var routeDidChange = action(function (store, loc, historyAction, state) {
    var routeLocation = parseRouteLocation(loc);
    var handler = ROUTES[routeLocation.pathname] || notFound;
    if (store.confirmUnload && historyAction === 'POP') {
        store.confirmHistory = confirmHistory;
    }
    else {
        confirmHistory();
    }
    function confirmHistory() {
        runInAction(function () {
            store.confirmHistory = undefined;
        });
        handler(store, routeLocation, state);
    }
});
