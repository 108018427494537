var shortWeekDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
var mediumWeekDayNames = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'];
var longWeekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var longMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
var shortMonthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
var pickerDayList = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
var data = {
    locale: 'en',
    otherLocale: 'nl',
    otherLocaleLong: 'Nederlands',
    pick: (function (choices) { return choices['en'] || choices['nl'] || choices[Object.keys(choices)[0]]; }),
    pickWithLocale: (function (choices, locale) { return choices[locale] || choices[Object.keys(choices)[0]]; }),
    shortWeekDayNames: shortWeekDayNames,
    mediumWeekDayNames: mediumWeekDayNames,
    longWeekDayNames: longWeekDayNames,
    longMonthNames: longMonthNames,
    shortMonthNames: shortMonthNames,
    pickerDayList: pickerDayList,
    formatShortMonthNames: function (d) { return "" + shortMonthNames[d.getMonth()]; },
    formatMediumDayName: function (d) { return "" + mediumWeekDayNames[d.getDay()]; },
    formatShortDay: function (d) { return mediumWeekDayNames[d.getDay()] + " " + d.getDate(); },
    formatMediumDate: function (d) { return mediumWeekDayNames[d.getDay()] + " " + d.getDate() + " " + shortMonthNames[d.getMonth()] + " " + d.getFullYear(); },
    formatLongDate: function (d) { return longWeekDayNames[d.getDay()] + " " + d.getDate() + " " + longMonthNames[d.getMonth()]; },
    formatNumericalYearMonthDate: function (d) { return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(); },
    formatLongMonthYear: function (d) { return longMonthNames[d.getMonth()] + " " + d.getFullYear(); },
    formatLongDayName: function (d) { return "" + longWeekDayNames[d.getDay()]; },
    formatDateRange: function (d1, d2) { return ((d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) ?
        mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()
        : (d1.getFullYear() === d2.getFullYear()) ?
            mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " " + shortMonthNames[d1.getMonth()] + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()
            :
                mediumWeekDayNames[d1.getDay()] + " " + d1.getDate() + " " + shortMonthNames[d1.getMonth()] + " " + d1.getFullYear() + " - " + mediumWeekDayNames[d2.getDay()] + " " + d2.getDate() + " " + shortMonthNames[d2.getMonth()] + " " + d2.getFullYear()); },
    // formatTime: (d) => `${(d.getHours()) % 12}:${d.getMinutes().toString().length < 2 ? d.getMinutes().toString() + '0' : d.getMinutes().toString()} ${d.getHours() >= 12 ? 'PM' : 'AM'}`,
    formatTime: function (d) { return d.getHours() + ":" + (d.getMinutes().toString().length < 2 ? '0' + d.getMinutes().toString() : d.getMinutes().toString()); },
    authHome: 'Home',
    authLinksSignIn: 'Log in',
    authLinksForgotPassword: 'Forgot your password?',
    authAlreadyHaveAccount: 'Do you have an account?',
    authCreateCompany: 'Create a New Company',
    authAlreadyCompany: 'Company already exist?',
    authAlreadyCompanyJoin: 'Join to my company with a code.',
    authAlreadyCompanyEnterCode: 'Enter the invitation code received by email or SMS.',
    authLogin: 'Log in',
    authDownloadApp: 'Please login with the mobile application or download it from the App Store or Google Play.',
    authExistingAccountTitle: 'Existing User',
    authExistingAccount: 'There is already an account associated with this email or mobile phone number.',
    authYouWillJoin: 'You will be joining',
    authJoin: 'Join',
    authSignYouIn: 'Sign in.',
    authNewOnSlot: 'Are you new to Slot?',
    authWelcomeToGivo: 'Welcome to Slot',
    authOr: 'or',
    authRegister: 'Create an account',
    authDiscover: 'Discover Slot',
    authLoginWithFacebook: 'Login with Facebook',
    authRegisterWithFacebook: 'Register with Facebook',
    avatarAlt: 'Avatar',
    avatarTitle: function (name) { return name; },
    alertModalTitle: 'Alert',
    alertModalOk: 'Ok',
    alertModalConfirm: 'Confirm',
    alertModalCancel: 'Cancel',
    alertModalApply: 'Apply on',
    alertModalShowLess: 'Show less',
    validationEmailError: 'Please enter your email address.',
    validationEmailValidError: 'Please enter a valid email address',
    validationPhoneValidError: 'Please enter a valid phone number',
    validationPasswordError: 'Please enter your password.',
    validationCodeError: 'Please enter the validation code you received via email or SMS.',
    validationPasswordLengthError: 'Your new password must be at least 6 characters.',
    validationPasswordConfirmError: 'Your password confirmation doesn\'t match.',
    validationFirstNameError: 'Please enter your first name.',
    validationLastNameError: 'Please enter your last name.',
    validationCompanyNameError: 'Please enter the name of your company.',
    validationPositionNameError: 'You must enter a name for this position.',
    validationLocationNameError: 'Please enter the name.',
    validationLocationStreetError: 'Please enter the street.',
    validationLocationCityError: 'Please enter the city.',
    validationSelectLocaitonError: 'Please select an address from your search result to add new location.',
    validationTeamListError: 'Please select at least one team from the list above.',
    validationTeamNameError: 'Please enter a name for this team.',
    validationCompanyCode: 'The invitation code must be 6 characters long.',
    validationEventRecurrence: 'The renewal date must be later than the current expiration date.',
    validationHourlyWageError: 'Hourly wage must be valid.',
    validationMaxHoursError: 'Max. weekly hours must be valid.',
    validationIndustryType: 'Please enter the type of your industry.',
    validationCompanyType: 'Please select the type of the industry.',
    validationCodeInvalid: 'The validation code is expired or invalid. Please request a new code.',
    validationTitleError: 'Please enter the title.',
    validationContentError: 'Please enter the content.',
    validationAboutError: 'Please enter the about text',
    validationAddressError: 'Please enter the address',
    validationLatitudeError: 'Please enter the latitude',
    validationLongitudeError: 'Please enter the longitude',
    validationOpeningsError: 'Please enter the availability',
    headerBarLogOut: 'Log Out',
    sideBarWelcome: 'Welcome,',
    sideBarProfile: 'My profile',
    sideBarDashboard: 'Dashboard',
    sideBarSchedule: 'Schedule',
    sideBarEmployees: 'Employees',
    sideBarSettings: 'Settings',
    sideBarHelp: 'Help',
    sideBarCopyright: "\u00A9 Slot, " + new Date().getFullYear(),
    sideBarQuickSetup: 'Quick Setup',
    sideBarSubscription: 'Subscription',
    sideBarCategories: 'Categories',
    sideBarSubCategories: 'Sub Categories',
    sideBarAdmins: 'Admins',
    sideBarClaims: 'Claims',
    sideBarCustomers: 'Customers',
    sideBarPushNotifications: 'Push Notifications',
    sideBarFaq: 'FAQ',
    sideBarFeed: 'RSS',
    loginViewEmailPlaceholder: 'Email Address',
    loginViewPasswordPlaceholder: 'Password',
    loginViewRememberMe: 'Remember me?',
    loginViewSignIn: 'Log in',
    loginViewNewUser: 'New User',
    loginViewEmployeeAlert: 'This console panel is only available for managers.',
    loginByContinuingYouAccept: 'By creating your account, your accept ',
    loginViewTermsAndConditions: 'Slot\'s Terms & Conditions.',
    loginErrorLogin: 'This email and password combination do not match any existing account.',
    loginErrorLocked: 'The account has been locked try again in 10 minutes.',
    loginLogoutMember: 'Back to Login',
    dashboardTitle: 'Dashboard',
    dashboardPhone: 'Phone',
    dashboardAbout: 'About',
    dashboardAddress: 'Address',
    dashboardLongitude: 'Longitude',
    dashboardLatitude: 'Latitude',
    dashboardOpenings: 'Availability time',
    actionYes: 'Yes',
    actionNo: 'No',
    actionDelete: 'Delete',
    actionCreate: 'Create',
    actionUpdate: 'Update',
    actionSend: 'Send',
    actionRefresh: 'Refresh',
    settingsTitle: 'Settings',
    settingsEdit: 'Edit',
    settingsSave: 'Save',
    homeSearch: 'Search',
    homeSearchFor: function (type) { return "Enter the name of the " + type; },
    homeSearchForOffer: 'Find an offer',
    homeSearchLocation: 'Select location',
    homeLatestOffers: 'Latest offers',
    homeFooterRights: 'Slot.nl - All righrs reserved',
    homeInitiative: 'Initiatief van',
    homeSupport: 'Ondersteund door',
    adminsTitle: 'Admins',
    adminsDeleteUserSummary: 'Are you sure you want to delete this admin?',
    adminsEmail: 'Email',
    adminsFirstName: 'First name',
    adminsLastName: 'Last name',
    adminsFirstNamePlaceholder: 'First name (optional)',
    adminsLastNamePlaceholder: 'Last name (optional)',
    adminsAction: 'Action',
    adminsCreateTitle: 'Create new admin',
    claimsTitle: 'Claims',
    customersTitle: 'Customers',
    pushNotificationsTitle: 'Push Notifications',
    pushNotificationSendTitle: 'Send push notification',
    pushNotificationsConfirm: 'Are you sure you want to send this message to all the users?',
    faqTitle: 'FAQ',
    faqFormTitle: 'Title',
    faqFormContent: 'Content',
    faqCreate: 'Create a FAQ',
    faqUpdate: 'Update FAQ',
    faqDeleteSummary: 'Are you surree you want to delete this item?',
    faqCategory: 'Category',
    faqCategoryFaq: 'Faq',
    faqCategoryClaim: 'Claim',
    feedTitle: 'RSS',
};
export default data;
